.form-control {
	height: 65px !important;
	margin-top: 1.5rem;
	position: relative;
	padding: 1rem !important;
	border: 3px solid $dark-grey!important;
	padding-bottom: 15px !important;
	// border-bottom: 1px solid $mid-white !important;
	background-color: $mid-white !important;
	color: $dark-grey !important;
	border-radius: 10px !important;
	font-size: 18px !important;;

	&:hover {
		box-shadow: none !important;
		border: unset;
	}
  
	&:focus {
		box-shadow: none !important;
		border: 3px solid #505050!important;
	}
}

textarea.form-control {
	display: flex!important;
	margin-top: 1.5rem!important;
	height: 153px!important;
	resize: none !important;
	overflow: hidden !important;
	margin-bottom: 0!important;
	padding: 18px 0 0 18px!important;
	font-size: 18 !important;
}

span.nh-roman {
	float: right;
	font-size: x-small;
	margin: 0.5rem 0.5rem 0 0;
}

.button-send {
	padding: 1.5em 1.4rem 1.4rem .7rem;
    height: 34px;
    width: 143px;
    background-color: #b7bab7;
    border: 1px solid #6e746e;
    outline: unset;
    text-decoration: none;
    border-radius: 20px !important;
    letter-spacing: .15rem;
    font-size: .9rem;
    transition: background-color .5s, letter-spacing .5s;
	position: relative;
	@media screen and (max-width: 991px) {
		margin-top: 2rem;
	}
	img.icon-arrow-right {
		right: 15px;
		position: absolute;
		width: auto;
		margin-left: 0.7rem;
		margin-top: -0.15rem;
		margin-right: 0.3rem;
		transform: rotate(180deg);
		transition: margin-left .5s, margin-right .5s;
	}
}
.button-send:hover {
    background-color: $black;
	img.icon-arrow-right {
		margin-right: 0rem;
		margin-left: 1.2rem;
	}
}

.both-form {
	justify-content: center !important;
}

.btn-form {
	background-color: #B18442 !important;
	width: 14rem;
	height: 4rem;
	border-bottom-right-radius: 50px !important;
	transition: all .3s !important;

	&:hover {
		border-bottom-right-radius: 4px !important;
	}
}
