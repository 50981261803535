.tasaciones,
.contacto {
    .main {
        overflow: hidden;
        // padding-top: 8.3rem;
        .full-fluid {
            min-height: calc(100vh);
        }
        .primary-row {
            min-height: calc(130vh);
            padding-bottom: 20vh;
            .left-column {
                position: relative;
                z-index: 2;
                span {
                    margin-bottom: 2rem;
                    font-family: 'LEMON MILK';
                    font-weight: 400;
                    display: block;
                    font-size: .85rem;
                    // letter-spacing: 3px;
                    color: #C3C3C3;
                    &.gray-color {
                        // color: #C3C3C3;
                    }
                }
                h2 {
                    margin-bottom: 3.3rem;
                    color: #fff;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-spacing: 5px;
                    line-height: 1.1;
                    display: block;
                    font-weight: 300;
                    font-size: 5.25rem;
                }
                &.step-1-column {
                    padding-top: 20vh;
                }
            }
            img {
                width: 100%;
                height: fit-content;
                object-fit: cover;
                object-position: center;
            }
        }
        .tick {
            width: 8rem;
            border-radius: 100%;
            margin-bottom: 2rem;
            height: 8rem;
            i {
                color: #0F0F0F;
                font-size: 2rem;
            }
        }
        &.step-2 {
            background-image: url('../../images/contacto-2.jpg');
            background-position: center;
            background-size: cover;
            background-attachment: fixed;
            background-color: #fff;
            background-repeat: no-repeat;
            min-height: 110vh;
            &::before {
                content: '';
                background-color: #000;
                opacity: .6;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                min-height: 200%;
            }
            h2 {
                font-family: 'Banana Grotesk';
                font-size: 3.1rem !important;
            }
            .full-fluid {
                .row {
                    padding-top: 12vh;
                    h2 {
                        color: #fff !important;
                        font-weight: 400;
                    }
                    .text-thanks {
                        font-size: 3.5rem;
                        font-weight: 300;
                    }
                }
            }
        }
        .content-form {
            width: 90%;
            margin: auto;
            padding-bottom: 10vh;
            input[type=text],
            input[type=tel],
            input[type=email],
            input[type=name],
            select,
            textarea {
                width: 100%;
                padding: 1.4rem 2rem;
                font-size: 1rem;
                background-color: transparent;
                color: #fff;
                border: 1px solid #fff !important;
                &::placeholder {
                    color: #fff;
                }
                &:focus {
                    border: 1px solid #fff !important;
                    outline: none;
                }
            }
            select {
                color: #fff;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                position: relative;
                background-color: transparent;
                z-index: 1;
                option {
                    color: #fff;
                    background-color: var(--secondary-color);
                }
            }
            i {
                position: absolute;
                z-index: -1;
                right: 2rem;
                top: calc(50% - .6rem);
                width: .75rem;
                height: .75rem;
                display: block;
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(-45deg);
                /* Giramos el cuadrado */
                transition: all 0.25s ease;
            }
            .border-bottom-name {
                background-color: #fff;
                width: 100%;
                height: 1px;
                margin: 3rem 0 4rem 0;
            }
            label {
                color: #fff;
                margin-bottom: 2rem;
                font-weight: 500;
                font-size: 1.2rem;
                margin-top: 2.5rem;
                letter-spacing: 0;
            }
            .option {
                display: inline-block;
                input {
                    display: none;
                    &:checked+label {
                        span {
                            color: #fff;
                            border: 0;
                            &:before {
                                background: var(--primary-color);
                                transform: translate(-50%, -50%) scale(1.15);
                            }
                        }
                    }
                }
                label {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: #fff;
                    font-weight: 300;
                    margin-top: 0;
                    span {
                        display: flex;
                        align-items: center;
                        color: #fff;
                        justify-content: center;
                        vertical-align: middle;
                        position: relative;
                        width: 3rem;
                        height: 3rem;
                        border: 1px solid transparent;
                        border-radius: 50%;
                        margin-bottom: 0;
                        &:before {
                            content: '';
                            display: inline-block;
                            transition: 0.3s;
                            position: absolute;
                            color: #fff;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) scale(0);
                        }
                    }
                    .square {
                        border-radius: 0;
                        &::before {
                            border-radius: 0;
                        }
                    }
                }
            }
            .option-check {
                margin-bottom: .5rem !important;
                font-family: 'Banana Grotesk' !important;
                input {
                    display: none;
                    &:checked+label {
                        span {
                            color: #fff;
                            border: 0;
                            &:before {
                                background: #fff;
                                transform: translate(-50%, -50%) scale(1.15);
                            }
                        }
                    }
                }
                label {
                    margin-right: 6rem;
                    span {
                        width: 22px;
                        height: 22px;
                        background: transparent;
                        border-radius: 50%;
                        margin-right: .5rem;
                        &:before {
                            content: '';
                            width: 9px;
                            height: 9px;
                            transition: 0.3s;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

.tasaciones {
    background-color: #0F0F0F;
    .breadcrumb-item+.breadcrumb-item {
        color: #fff;
        &::before {
            color: #fff;
        }
    }
}

@media (min-width:992px) {
    .tasaciones,
    .contacto {
        .main {
            .full-fluid {
                max-width: 95%;
                margin-left: auto;
                padding-right: 0;
                margin-right: 0;
                position: relative;
            }
        }
    }
}

@media (max-width:992px) {
    .tasaciones {
        .main {
            min-height: 90vh;
            padding-top: 0;
            overflow: hidden;
            .full-fluid {
                min-height: 90vh;
            }
            &.step-0 {
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    height: 90vh;
                    background-color: var(--secondary-color);
                    opacity: 0.6;
                    z-index: 9;
                    content: '';
                }
            }
            .primary-row {
                min-height: 90vh;
                &.bg-transparent {
                    &::before {
                        background-color: transparent;
                    }
                }
                .left-column {
                    position: relative;
                    z-index: 10;
                    padding-top: 4rem;
                    span {
                        margin-bottom: 1rem;
                        font-size: 1rem;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        // color: #fff;
                    }
                    h2 {
                        margin-bottom: 1.8rem;
                        white-space: unset;
                        font-size: 2rem;
                    }
                    &.step-1-column {
                        padding-top: 5vh;
                        z-index: 99;
                        span,
                        h2 {
                            color: #fff;
                        }
                    }
                }
                img {
                    height: 90vh;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                }
            }
            .content-form {
                padding: 0;
                width: 100%;
                z-index: 99;
                position: relative;
                input[type=text],
                input[type=tel],
                input[type=email],
                select,
                textarea {
                    padding: .8rem 1rem;
                    font-size: .8rem;
                    margin-bottom: .8rem;
                }
                .option-check {
                    label {
                        margin-right: 0;
                        text-transform: capitalize;
                        font-size: .9rem;
                    }
                }
                label {
                    margin-bottom: 1.5rem;
                    font-size: .95rem;
                }
                i {
                    top: calc(50% - 1rem);
                }
                .btn {
                    margin-bottom: 10vh;
                    margin-top: 3vh !important;
                }
                p {
                    font-size: .9rem;
                    color: #fff;
                }
                .border-bottom-name {
                    margin: 2rem 0 2.5rem 0;
                }
            }
            .tick {
                width: 5rem;
                margin-bottom: 2rem;
                height: 5rem;
                i {
                    color: #fff;
                    font-size: 1.5rem;
                }
            }
            .btn {
                display: block;
                margin-top: 3vh;
                padding-top: 1rem;
                padding-bottom: .8rem;
                line-height: 1;
                font-size: .9rem;
            }
            &.step-2 {
                min-height: 90vh;
                padding-bottom: 0vh;
                // display: flex;
                // align-items: center;
                // .full-fluid {
                .primary-row {
                    padding-bottom: 4rem;
                }
                .row {
                    background: transparent !important;
                    padding-top: 0vh;
                    h2,
                    .text-thanks {
                        font-size: 2rem !important;
                        font-weight: 300;
                    }
                }
                // }
            }
        }
    }
}