.brand {
    background-color: var(--primary-color);
    border-radius: 50%;
    position: absolute;
    top: -264px;
    left: -187px;
    width: 35rem;
    height: 35rem;
    z-index: 7;
}

.brand__image {
    position: absolute;
    top: 65%;
    left: 40%;
    width: 15rem;
}

.sidebar {
    width: 250px;
    height: 100vh;
    background-color: var(--secondary-color);
    display: none;
    z-index: 999999;
}

.vertical__title__container {
    height: 20rem;
}

.sidebar__vertical {
    color: var(--primary-color);
    font-size: 1.5vh;
    width: 250px;
}

.vertical__title {
    text-transform: uppercase;
    letter-spacing: .2rem;
    white-space: nowrap;
    transform: rotate(-90deg);
}

.vertical__social__container {
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;
}

.vertical__social__item {
    font-size: 1.5vh;
    line-height: 3rem;
    a {
        // font-size: 1.2rem;
        font-size: 2rem;
        text-decoration: none;
        color: var(--primary-color);
        transition: all .3s;
        &:hover {
            color: var(--white);
        }
    }
}

@media screen and (max-height: 880px) {
    .sidebar__vertical .h-50 {
        height: 40% !important;
    }
}

@media screen and (max-height: 700px) {
    .vertical__title__container {
        visibility: hidden !important;
    }
}