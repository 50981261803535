.main__section__about {
    padding: 8rem 0;
    background-color: var(--secondary-color);
    position: relative;
}

.article__about__image {
    width: 35vw;
    height: 45vw;
}

.article__image__number {
    position: absolute;
    font-size: 17vw;
    top: 15px;
    right: 5rem;
    margin: 0;
    -webkit-text-stroke: 2px #fff;
    color: #00000000;
}

.article__container__description {
    width: 100%;
    margin: 3rem 0;
}

.article__container__title {
    height: 250px;
    padding: 0;
}

.article__title {
    font-size: 2rem;
}

.article__text {
    margin-bottom: 2rem;
}

.article__container__text {
    height: auto;
    width: 55vw;
    max-width: 780px;
    font-size: 18px;
    padding: 32px 16px;
    >p {
        margin: 0;
        color: #C1BCBC;
    }
}

.circlePrimary {
    display: none;
    position: absolute;
    top: 50%;
    bottom: 50;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 35vw;
}

@media screen and (min-width: 1200px) {
    .main__section__about {
        height: 100vh;
    }
    .article__image__number {
        right: -10rem;
    }
    .article__container__description {
        width: 650px;
    }
    .article__container__title {
        font-size: 2vw;
        margin-left: 10rem;
        padding: 1rem;
    }
    .article__title {
        text-align: left !important;
    }
    .article__text {
        text-align: left !important;
    }
    .article__container__text {
        height: 440px;
    }
}

@media (max-width:992px) {
    .main__section__about {
        padding: 5rem 0;
        padding-bottom: 0;
    }
    .article__container__title {
        max-height: auto;
        height: auto;
        line-height: 1;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    .article__about__container__image {
        display: block !important;
    }
    .article__about__image {
        width: 80vw;
        display: block;
        margin: auto;
        height: auto;
    }
    .article__image__number {
        position: relative;
        font-size: 5rem;
        line-height: 0;
        margin-top: .5rem;
        left: auto;
        right: auto;
    }
    .article__container__text {
        max-width: 100%;
        width: 90vw;
        padding: 0;
        .buttonAnimation {
            margin-top: 2rem;
        }
    }
    .article__container__description {
        margin-top: 2.5rem;
    }
}