.novedades {
    .main__first__section__novedades {
        width: 100vw;
        .main__first__section__container__novedades {
            position: relative;
            width: 100%;
            .main__first__section__background__novedades {
                width: 100%;
                height: 392px;
                object-fit: cover;
                object-position: center;
            }
            .main__first__section__title__novedades {
                position: absolute;
                top: 0%;
                color: var(--white);
                height: 392px;
                width: 100%;
                .main__first__section__title__container__novedades {
                    h1 {
                        color: $color-1;
                        letter-spacing: 0 !important;
                        margin-bottom: 10rem;
                        margin-right: 10rem;
                    }
                }
            }
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: #1c1c1c31 0% 0% no-repeat padding-box;
            }
        }
    }
}

@media (max-width: 992px) {
    .novedades {
        .main__first__section__novedades {
            .main__first__section__container__novedades {
                .main__first__section__title__novedades {
                    left: 0;
                    .main__first__section__title__container__novedades {
                        h1 {
                            text-align: center;
                        }
                        .div_bottom_arrow {
                            a {
                                span {
                                    left: 20px;
                                }
                                &:hover {
                                    span {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .novedades {
        .main__first__section__title__container__novedades {
            margin-top: 10vh !important;
            p {
                font-size: 1.2rem;
                br {
                    display: none;
                }
            }
        }
    }
}