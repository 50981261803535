.header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 0;
    z-index: 9999;
}

.header__top {
    height: 50px;
    z-index: 9999;
    position: fixed;
    width: 100%;
    top: 0;
    &.bg-light-grey {
        background-color: $light-grey;
    }
}

.header__emprendimiento__title {
    color: #fff;
    display: block;
    width: inherit;
    text-align: center;
    letter-spacing: .15rem;
}

.button__container {
    position: absolute;
    right: 0;
    a {
        text-decoration: none !important;
    }
}

.btn-link-wrap {
    .button__emprendimiento {
        padding: .7rem 1.4rem;
        height: 34px;
        width: 143px;
        background-color: $light-grey;
        border: 1px solid $dark-grey;
        outline: unset;
        text-decoration: none;
        border-radius: 20px!important;
        letter-spacing: .15rem;
        font-size: .9rem;
        transition: font-size .5s, background-color .5s, letter-spacing .5s;
        img.icon-arrow-left {
            width: auto;
            margin-left: 0.3rem;
            margin-right: 0.7rem;
            transition: margin-left .5s, margin-right .5s;
        }
    }
}

.btn-link-wrap:hover {
    .button__emprendimiento {
        font-size: .7rem;
        letter-spacing: .20rem;
        background-color: $black;
        img.icon-arrow-left {
            margin-left: 0rem;
            margin-right: 1.2rem;
        }
    }    
}

.main__second__header__emprendimiento {
    height: 75px;
    z-index: 1111;
    position: fixed;
    width: 100%;
    top: 0;
    &.bg-dark-grey {
        background-color: $dark-grey;
    }
    &.bg-dark-transparent {
        background-color: #6e746e85;
        top: 50px;
    }
    img {
        height: 30px;
        margin-top: 0.25rem;
    }
    .ig-wrap {
        height: 40px
    }
    li,
    .ig-wrap {
        a {
            color: #fff;
            letter-spacing: 3px;
            text-decoration: none;
            font-size: .9rem;
            &:hover {
                color: #000;
                text-decoration: none;
            }
        }
    }
    .ig-wrap {
        a {    
            padding-top: 0.25rem;
            position: absolute;
            font-size: 1.6rem;
            right: 0;
        }
    }
}

@media (max-width:992px) {
    .header__top {
        padding: .2rem 0;
        height: unset;
        .header__emprendimiento__title {
            font-size: .8rem;
            width: auto;
        }
    }
    .main__second__header__emprendimiento {
        nav {
            display: none !important;
        }
    }
    .button__container {
        position: relative;
        margin: .5rem 0;
        margin-left: auto;
        text-align: center;
        display: inline-block;
        width: auto;
        font-size: .8rem;
    }
}