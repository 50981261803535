.emprendimientos {
    .main {
        height: 100vh;
        overflow: hidden;
        position: relative;
        .content-image {
            position: relative;
            height: 85vh;
            transition: .4s ease all;
            overflow: hidden;
            img {
                height: 100%;
                object-fit: cover;
                object-position: center;
                width: 100%;
            }
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                content: '';
                // background-color: #0f0f0f;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.751278182171306) 0%, rgba(115, 115, 115, 0) 40%, rgba(255, 255, 255, 0) 100%);
                opacity: 1;
                z-index: 9;
                display: block;
            }
        }
        .content-slider {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 9;
            left: 5%;
            width: max-content;
            height: 15vh;
            .item {
                position: relative;
                margin-right: 7vw;
                z-index: 9;
                h3 {
                    span {
                        font-style: 1.5rem;
                        color: #0F0F0F;
                        font-weight: 300;
                    }
                }
                .arrows {
                    display: none;
                    cursor: pointer;
                    .next,
                    .back {
                        border-radius: 50%;
                        i {
                            color: var(--primary-color);
                        }
                        &:hover {
                            border: 2px solid transparent;
                            background-color: #fff !important;
                            i {
                                color: #0f0f0f!important;
                            }
                        }
                    }
                }
                h5,
                a {
                    display: none;
                }
                &.active {
                    padding: 2.35rem 3rem;
                    width: 30vw;
                    // height: 24rem;
                    position: relative;
                    color: #fff;
                    margin-bottom: 13.13rem;
                    display: inline-block;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: -3.94rem;
                        // width: 4rem;
                        border-top: 4rem solid transparent;
                        border-left: 4rem solid #cc912a;
                        bottom: 0;
                        width: 0;
                    }
                    .line-location {
                        width: 4rem;
                        height: 2px;
                        background-color: #fff;
                    }
                    h3 {
                        font-size: 3.75rem;
                        display: block;
                        font-weight: 300;
                        width: 110%;
                        margin-bottom: 1.8rem;
                    }
                    h5 {
                        font-size: 1rem;
                        font-family: 'LEMON MILK';
                        display: block;
                        text-transform: uppercase;
                        letter-spacing: 1.5px;
                        font-weight: 200;
                    }
                    a {
                        margin-top: 5rem;
                        display: block;
                        font-size: 1rem;
                        font-weight: 300;
                        color: #0F0F0F;
                        text-decoration: none;
                        text-decoration: underline;
                        padding-bottom: 0;
                        margin-bottom: 0;
                        transition: .3s ease all;
                        &:hover {
                            letter-spacing: 1px;
                        }
                    }
                    .arrows {
                        display: block;
                        opacity: 1;
                        .next {
                            width: 3.5rem;
                            background-color: var(--secondary-color);
                            color: #fff;
                            height: 3.5rem;
                            font-size: 1.2rem;
                            position: absolute;
                            right: -5.5rem;
                            top: calc(50% - 1.5rem);
                        }
                        .back {
                            width: 3.5rem;
                            background-color: var(--secondary-color);
                            color: #fff;
                            transform: rotate(180deg);
                            height: 3.5rem;
                            font-size: 1.2rem;
                            position: absolute;
                            left: -2rem;
                            top: calc(50% - 1.5rem);
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:992px) {
    .emprendimientos {
        .full-container {
            max-width: 95%;
            margin-left: auto;
        }
    }
}

@media (max-width:992px) {
    .emprendimientos {
        .main {
            .content-slider {
                .item {
                    margin-left: 1rem;
                    h5,
                    a {
                        display: none;
                    }
                    &.active {
                        padding: 1.35rem 2rem;
                        width: 15rem;
                        // height: 1rem;
                        margin-bottom: 20rem;
                        &::before {
                            z-index: -1;
                        }
                        h3 {
                            font-size: 1.8rem;
                            margin-bottom: 1.5rem;
                            width: calc(100% + 3.5rem);
                        }
                        h5 {
                            font-size: .8rem;
                            width: calc(100% + 4rem);
                            .line-location {
                                width: 3rem;
                            }
                        }
                        a {
                            margin-top: 4rem;
                            font-size: .8rem;
                        }
                        .arrows {
                            display: block;
                            opacity: 1;
                            .next {
                                width: 2.5rem;
                                background-color: var(--secondary-color);
                                color: #fff;
                                height: 2.5rem;
                                font-size: 1rem;
                            }
                            .back {
                                width: 2.5rem;
                                background-color: var(--secondary-color);
                                color: #fff;
                                height: 2.5rem;
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}