:root {
  --primary-color: #b18442;
  --secondary-color: #151a2f;
  --btn-primary-color: #d39d2a;
  --btn-secondary-color: #ac842d;
  --btn-primary-border: #d39d2a;
  --color-0: #d39d2a;
  --color-2: #0e1951;
  --link-footer: #1caff0;
  --white: #ffffff;
  --links-color: #fffaf5;
  --aera-color: #99b6df;
  --campos-color: #3f5b42;
  --cerrito-color: #a361bf;
  --cerritomio-color: #ac2244;
  --cochabamba-color: #e54c29;
  --rivadavia-color: #61bfa6;
  --cantilo-color: #adbbff;
  --pareja-color: #5aab69;
  --card-bg: #2d3143;
  /* font-sizes */
  --navbar-links-size: 14px;
  --main-title-size: 40px;
  --main-title-our-devs: 50px;
  --main-text-size: 16px;
  --btn-text-size: 20px;
  --main-card-text-size: 23px;
  --font-size-18: 1.2rem;
  --font-size-18-mobile: 1rem;
  --font-size-20: 1.25rem;
  --font-size-20-mobile: 1.05rem;
  --font-size-21: 1.3rem;
  --font-size-21-mobile: 1.1rem;
  --font-size-23: 1.4rem;
  --font-size-23-mobile: 1.2rem;
  --font-size-24: 1.5rem;
  --font-size-24-mobile: 1.25rem;
  --font-size-25: 1.56rem;
  --font-size-25-mobile: 1.3rem;
  --font-size-30: 1.87rem;
  --font-size-30-mobile: 1.5rem;
  --font-size-38: 2.4rem;
  --font-size-38-mobile: 2rem;
  --font-size-40: 2.5rem;
  --font-size-40-mobile: 2.1rem;
  --font-size-50: 3rem;
  --font-size-50-mobile: 2.5rem;
  --font-size-51: 4.2rem;
  --font-size-51-mobile: 2.6rem;

  --font-size-60: 6vmin;
  --font-size-75: 2.5vw;
}

// Colores

$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$color-0: var(--color-0);
$color-1: var(--white);
$color-2: var(--color-2);

$dark-grey: #6e746e;
$light-grey: #b7bab7;
$light-grey2: #ebebeb;
$mid-grey: #c0bcbb;
$mid-white: #e9e9e9;
$mid-white2: #ebebeb;
$black: #000000;
$mid-black: #505050;
$light-black: #5d635f;
$yellow: #d8b41e;
$green: #0e9a34;
$light-green: #27c64c;

// bordes

$bd-0: var(--btn-primary-border);

// Backgrounds
$bg-card: var(--card-bg);

body {
  color: var(--white) !important;
}

.link-footer {
  color: var(--link-footer);
}

.text-primary-orange {
  color: var(--primary-color);
}

.fs-12 {
  font-size: var(--font-size-12);
}

.fs-13 {
  font-size: var(--font-size-13);
}

.fs-14 {
  font-size: var(--navbar-links-size) !important;
}

.fs-40 {
  font-size: 2.2rem;
}

.fs-50 {
  font-size: 2.6rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: var(--font-size-18);
}

.fs-20 {
  font-size: var(--font-size-20);
}

.fs-21 {
  font-size: var(--font-size-21);
}

.fs-23 {
  font-size: var(--font-size-23);
}

.fs-24 {
  font-size: var(--font-size-24);
}

.fs-25 {
  font-size: var(--font-size-25);
}

.fs-30 {
  font-size: var(--font-size-30);
}

.fs-38 {
  font-size: var(--font-size-38);
}

.fs-40 {
  font-size: var(--font-size-40);
}

.fs-50 {
  font-size: var(--font-size-50);
}
.fs-51 {
  font-size: var(--font-size-51);
}

.fs-60 {
  font-size: var(--font-size-60);
}

.fs-75 {
  font-size: var(--font-size-75);
}

@media (max-width: 1300px) {
  .fs-18 {
    font-size: var(--font-size-18-mobile);
  }
  .fs-20 {
    font-size: var(--font-size-20-mobile);
  }
  .fs-21 {
    font-size: var(--font-size-21-mobile);
  }
  .fs-23 {
    font-size: var(--font-size-23-mobile);
  }
  .fs-24 {
    font-size: var(--font-size-24-mobile);
  }
  .fs-25 {
    font-size: var(--font-size-25-mobile);
  }
  .fs-30 {
    font-size: var(--font-size-30-mobile);
  }
  .fs-38 {
    font-size: var(--font-size-38-mobile);
  }
  .fs-40 {
    font-size: var(--font-size-40-mobile);
  }
  .fs-50 {
    font-size: var(--font-size-50-mobile);
  }
  .fs-51 {
    font-size: var(--font-size-51-mobile);
  }
}

// .sspro {
//   font-family: "sourceSansPro";
// }

// .sspro-light {
//   font-family: "sourceSansPro-light";
// }

// .sspro-regular {
//   font-family: "sourceSansPro-regular";
// }

// .montserrat-light {
//   font-family: "montserrat light";
// }

// .montserrat-regular {
//   font-family: "montserrat regular";
// }

// .montserrat-semibold {
//   font-family: "montserrat semibold";
// }

// .montserrat-medium {
//   font-family: "montserrat medium";
// }

// .montserrat-bold {
//   font-family: "montserrat bold";
// }

// .montserrat-extrabold {
//   font-family: "montserrat extrabold";
// }

// .montserrat-black {
//   font-family: "montserrat black";
// }

// .raleway-extrabold {
//   font-family: "raleway-extrabold";
// }

.aera {
  // Ejemplo con AERA
  .proyect-color {
    color: #99b6df !important;
    border-color: #99b6df !important;
  }
  .proyect-bg {
    background: #99b6df !important;
  }
}

.campos {
  // Ejemplo con CAMPOS
  .proyect-color {
    color: var(--campos-color) !important;
    border-color: var(--campos-color) !important;
  }
  .proyect-bg {
    background: var(--campos-color) !important;
  }
}

.cochabamba {
  .proyect-color {
    color: var(--cochabamba-color) !important;
    border-color: var(--cochabamba-color) !important;
  }
  .proyect-bg {
    background: var(--cochabamba-color) !important;
  }
}

.cerrito2142 {
  .proyect-color {
    color: var(--cerrito-color) !important;
    border-color: var(--cerrito-color) !important;
  }
  .proyect-bg {
    background: var(--cerrito-color) !important;
  }
}

.cerritomio {
  .proyect-color {
    color: var(--cerritomio-color) !important;
    border-color: var(--cerritomio-color) !important;
  }
  .proyect-bg {
    background: var(--cerritomio-color) !important;
  }
}

.rivadavia {
  .proyect-color {
    color: var(--rivadavia-color) !important;
    border-color: var(--rivadavia-color) !important;
  }
  .proyect-bg {
    background: var(--rivadavia-color) !important;
  }
}

.cantilo4645,
.cantilo-4645 {
  .proyect-color {
    color: var(--cantilo-color) !important;
    border-color: var(--cantilo-color) !important;
  }
  .proyect-bg {
    background: var(--cantilo-color) !important;
  }
}

.pareja3645 {
  .proyect-color {
    color: var(--pareja-color) !important;
    border-color: var(--pareja-color) !important;
  }
  .proyect-bg {
    background: var(--pareja-color) !important;
  }
}

// @font-face {
//   font-family: "montserrat light";
//   src: url("../../fonts/Montserrat-Light.ttf");
// }

// @font-face {
//   font-family: "montserrat regular";
//   src: url("../../fonts/Montserrat-Regular.ttf");
// }

// @font-face {
//   font-family: "montserrat semibold";
//   src: url("../../fonts/Montserrat-SemiBold.ttf");
// }

// @font-face {
//   font-family: "montserrat bold";
//   src: url("../../fonts/Montserrat-Bold.ttf");
// }

// @font-face {
//   font-family: "montserrat extrabold";
//   src: url("../../fonts/Montserrat-ExtraBold.ttf");
// }

// @font-face {
//   font-family: "montserrat black";
//   src: url("../../fonts/Montserrat-Black.ttf");
// }

// @font-face {
//   font-family: "montserrat medium";
//   src: url("../../fonts/Montserrat-Medium.ttf");
// }

// @font-face {
//   font-family: "sourceSansPro";
//   src: url("../../fonts/SourceSansPro-Regular.otf");
// }

// @font-face {
//   font-family: "sourceSansPro-light";
//   src: url("../../fonts/SourceSansPro-Light.otf");
// }

// @font-face {
//   font-family: "sourceSansPro-regular";
//   src: url("../../fonts/SourceSansPro-Regular.otf");
// }

/* Neue Haas Font Load */
@font-face {
  font-family: 'NeueHaas-Black';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-Black.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Black.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-BlackItalic';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-BlackItalic.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-BlackItalic.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-Bold';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-Bold.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Bold.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-BoldItalic';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-BoldItalic.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-BoldItalic.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-Medium';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-Medium.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Medium.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-MediumItalic';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-MediumItalic.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-MediumItalic.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-Roman';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-Roman.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Roman.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Roman.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-RomanItalic';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-RomanItalic.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-RomanItalic.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-RomanItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-Light';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-Light.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Light.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-LightItalic';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-LightItalic.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-LightItalic.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-Thin';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-Thin.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Thin.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-ThinItalic';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-ThinItalic.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-ThinItalic.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-XThin';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-XThin.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-XThin.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-XThin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-XThinItalic';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-XThinItalic.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-XThinItalic.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-XThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-XXThin';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-XXThin.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-XXThin.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-XXThin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaas-XXThinItalic';
  src: url('../../fonts/neuehaas/NeueHaasDisplay-XXThinItalic.ttf') format('truetype'),
       url('../../fonts/neuehaas/NeueHaasDisplay-XXThinItalic.woff2') format('woff2'),
       url('../../fonts/neuehaas/NeueHaasDisplay-XXThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

/* Neue Haas Font-Family class */
.nh-black { font-family: 'NeueHaas-Black'!important; }
.nh-black-it { font-family: 'NeueHaas-BlackItalic'!important; }
.nh-bold { font-family: 'NeueHaas-Bold'!important; }
.nh-bold-it { font-family: 'NeueHaas-BoldItalic'!important; }
.nh-medium { font-family: 'NeueHaas-Medium'!important; }
.nh-medium-it { font-family: 'NeueHaas-MediumItalic'!important; }
.nh-roman { font-family: 'NeueHaas-Roman'!important; }
.nh-roman-it { font-family: 'NeueHaas-RomanItalic'!important; }
.nh-light { font-family: 'NeueHaas-Light'!important; }
.nh-light-it { font-family: 'NeueHaas-LightItalic'!important; }
.nh-thin { font-family: 'NeueHaas-Thin'!important; }
.nh-thin-it { font-family: 'NeueHaas-ThinItalic'!important; }
.nh-xthin { font-family: 'NeueHaas-XThin'!important; }
.nh-xthin-it { font-family: 'NeueHaas-XThinItalic'!important; }
.nh-xxthin { font-family: 'NeueHaas-XXThin'!important; }
.nh-xxthin-it { font-family: 'NeueHaas-XXThinItalic'!important; }