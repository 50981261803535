.Inicio {
  .content {
    .div_news {
      .container-fluid {
        .div_news_container {
          h2 {
          }
          .hilera {
            margin-top: 7rem;
          }
          .div_button {
            margin-top: 7rem;
          }
        }
      }
    }
  }
}
