.buttonAnimation {
    height: 5rem;
    background-color: var(--btn-secondary-color);
    border-radius: 0;
    border-bottom-right-radius: 30px;
    transition: all .3s;
    outline: unset;
    border: unset;
    color: white;
    font-size: 1.2rem;
    padding: 0 4rem;
    &:hover {
        border-bottom-right-radius: 0px;
    }
    &:disabled {
        opacity: .4;
        cursor: not-allowed;
    }
}

.buttonAnimationGray {
    background-color: unset !important;
    border: 1px solid #FFFAF5 !important;
    margin: 0 1rem;
    font-size: 20px;
}

@media (max-width:992px) {
    .buttonAnimation {
        padding: 0 2rem;
        height: 3.5rem;
        font-size: .8rem;
    }
}