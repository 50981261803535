* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    font-size: 16px;
}

body {
    font-size: 16px;
    font-family: "montserrat regular", sans-serif;
    font-weight: 100;
    overflow-x: hidden;
    background-color: $dark-grey;
}

a {
    text-decoration: none;
}

._3bkri {
    background-color: transparent !important;
}

._3bkri:hover {
    background-color: transparent !important;
}

.section_indicator {
    border-bottom: 2px solid var(--secondary-color);
    padding-bottom: 0.9rem;
    margin-bottom: 4rem;
    span {
        width: 100%;
        font-size: 0.68rem;
        font-weight: 300;
    }
}

.line-vertical {
    // height: 100vh;
    // width: 100%;
    border-left: 1px solid #fff;
    display: block;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;
}

p {
    font-weight: 100;
}

.border-none {
    border: none;
}

#text-form {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.see_description {
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 600;
    text-decoration: none;
    color: var(--primary-color);
    &:hover {
        text-decoration: none;
        color: var(--primary-color);
    }
}

.no-link {
    color: #fff;
    &:hover {
        color: #fff;
        text-decoration: none;
    }
}

.social-media {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    .single {
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        transition: 0.4s ease all;
        align-items: center;
        justify-content: center;
        // background-color: $secondary-color;
        cursor: pointer;
        margin-right: 2rem;
        border-bottom: none !important;
        text-decoration: none;
        .small-single {
            width: 0.9rem;
        }
        img {
            width: 1.5rem;
        }
        i {
            font-size: 1.4rem;
            color: var(--primary-color);
        }
        &:hover {
            i {
                color: var(--primary-color);
            }
        }
    }
}

.breadcrumb-item {
    font-size: 1rem;
    text-decoration: underline;
    a {
        font-size: 1rem;
        color: var(--primary-color);
    }
}

.breadcrumb-item+.breadcrumb-item {
    padding: 0 0 0 .5rem;
    font-size: 1rem;
    &::before {
        content: ">";
        padding: 0 1rem 0 0.5rem;
    }
    a {
        padding-left: 0rem;
        font-size: 1rem;
        color: var(--primary-color);
    }
}

.cursor-pointer {
    cursor: pointer;
}

.fixed-bar {
    bottom: 5vh;
    right: 5%;
    z-index: 9999999;
    opacity: 1;
    display: flex;
    transition: 0.4s ease all;
    &.opacity {
        opacity: 0;
    }
    .btn {
        padding: 0;
        position: relative;
        z-index: 98;
        width: 5.5rem;
        height: 5.5rem;
        font-size: 1.5rem;
        color: transparent;
        background: var(--secondary-color);
        border: 2px solid #fff;
        margin-left: 1rem;
        transition: 0.2s ease all;
        &:hover {
            border: 2px solid var(--primary-color);
            span {
                color: #fff !important;
            }
        }
        &.disabled {
            opacity: 0.3 !important;
            cursor: not-allowed !important;
            pointer-events: unset !important;
        }
    }
    &.opacity-total {
        opacity: 0;
    }
    .contact-btn {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $green !important;
        color: #fff !important;
        font-size: 2.5rem;
        position: relative;
        text-decoration: none;
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            background: $light-green !important;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0);
            will-change: transform;
            transition: 0.4s;
            border-radius: 50%;
        }
        &:hover:after {
            transform: scale(1);
            opacity: 1;
        }
        &:before {
            z-index: 9;
            transition: 0.3s;
            transition-delay: 0.1s;
        }
        &:hover {
            color: #fff !important;
        }
    }
}

.btn {
    position: relative;
    display: flex !important;
    align-items: center;
    background: transparent;
    width: fit-content;
    color: var(--primary-color);
    height: 3.2rem;
    padding: 0 2.5rem;
    padding-left: calc(2.5rem + 3rem);
    font-size: 1rem;
    letter-spacing: -0.1px;
    border-radius: 0;
    transition: 0.3s ease all;
    text-decoration: underline;
    &::before {
        font-family: "icomoon" !important;
        content: "\e90d";
        font-size: 0.9rem;
        line-height: 1;
        display: flex;
        padding-bottom: 3px;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        width: 3.2rem;
        height: 3.2rem;
        top: -4px;
        z-index: 1;
        bottom: 0;
        text-transform: uppercase;
        border-radius: 50%;
        color: #0d0d0d;
        transition: all 0.5s ease;
    }
    &:hover {
        letter-spacing: 0.1px;
        color: var(--primary-color);
        &::before {
            transform: translateX(1rem);
        }
    }
    &.transparent {
        background: transparent;
    }
    &.black {
        color: #0d0d0d;
        &::before {
            background: #0d0d0d !important;
            color: #fff;
        }
    }
}

.btn-square {
    padding: 1.2rem 2rem;
    color: #fff;
    z-index: 90;
    transition: 0.3s ease all;
    cursor: pointer;
    &.dark-hover {
        &:hover {
            background: var(--secondary-color);
        }
    }
    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.social-block {
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    z-index: 1;
    &.hidden {
        transition: all 0.3s ease;
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        right: 0;
        opacity: 0;
        z-index: -1;
        position: absolute;
    }
    .btn-square {
        width: 2.5rem !important;
        z-index: -1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5rem !important;
        i {
            margin: 0 !important;
        }
        &:hover {
            border: none;
        }
    }
}

.index-1 {
    z-index: 1;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 40%;
    .line-vertical {
        opacity: 0.25;
        width: 1px;
        height: 100%;
    }
}

.w-auto {
    // width: auto !important;
}

.text-primary-color {
    color: var(--primary-color);
}

.map {
    height: 100%;
}

.pill {
    padding: 0.5rem 1rem;
    color: white;
    background: rgba(255, 255, 255, 0.24);
    font-size: 1.1em;
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: fit-content;
    height: fit-content;
}

.section__video__emprendimiento {
    .text-container {
        margin: 0 0 3rem 0;
        h2.nh-medium.text-center {
            font-size: 2.5rem;
            letter-spacing: .25rem;
        }
        &::before {
            top: 0;
            right: 5%;
            opacity: .05;
            content: 'Video';
            font-size: 14rem;
            position: absolute;
            letter-spacing: .5rem;
            text-transform: uppercase;
            font-family: 'NeueHaas-Bold';
            transform: translate(0%, -50%);
        }
    }
}

#ubicacion.location-wrapper {
    margin-top: 8em;
    .container.title-container {
        h2.nh-medium.text-center {
            font-size: 2.5rem;
            letter-spacing: .25rem;
        }
        &::before {
            top: 0;
            right: 0;
            opacity: .05;
            content: 'Mapa';
            font-size: 14rem;
            position: absolute;
            letter-spacing: .5rem;
            text-transform: uppercase;
            font-family: 'NeueHaas-Bold';
            transform: translate(115%, -50%);
        }
    }
    h3.nh-roman {
        opacity: 0.5;
        margin-top: 2rem;
        color: var(--white);
    }
}

@media (max-width: 992px) {
    .section_indicator {
        border-bottom: 1px solid var(--secondary-color);
        padding-bottom: 5px;
        margin-bottom: 2rem;
        span {
            font-size: 0.4rem;
        }
    }
    .overflow-hidden-mobile {
        overflow: hidden !important;
        height: 100vh;
    }
    .social-media {
        width: 100%;
        text-decoration: none;
        .single {
            width: 1.6rem;
            margin-right: 0.5rem;
            height: 1.6rem;
            .small-single {
                width: 0.6rem;
            }
            img {
                width: 0.8rem;
            }
        }
    }
    .btn {
        height: 3.2rem;
        padding: 0 2rem;
        padding-left: calc(2rem + 3rem);
        &::before {
            width: 3.2rem;
            height: 3.2rem;
        }
    }
    .breadcrumb-item {
        font-size: 0.9rem;
        a {
            font-size: 0.9rem;
        }
    }
    .breadcrumb-item+.breadcrumb-item {
        font-size: 0.9rem;
        &::before {
            padding: 0 0.3rem;
        }
        a {
            font-size: 0.9rem;
        }
    }
    .fixed-bar {
        bottom: 5vh;
        right: 5%;
        .contact-btn {
            width: 4rem;
            height: 4rem;
            font-size: 1.5rem;
        }
        .btn.go-top.active {
            width: 4rem;
            height: 4rem;
        }
    }
    .map {
        height: 55vh;
        position: relative;
    }
    .w-80-sm {
        width: 80%;
    }
    .index-1 {
        width: 100%;
        .line-vertical {
            opacity: 0.1 !important;
        }
    }
    .see_description {
        font-size: 1rem;
        margin-left: 0rem;
        width: 100%;
        display: block;
        margin-top: 0.5rem;
        &::after {
            content: "...";
        }
    }
    .pill {
        font-size: .7rem;
        padding: 0.5rem 0.8rem;
        margin-right: 0.3rem;
    }
}

@media (min-width: 992px) {
    .container-fluid {
        max-width: 90%;
    }
}

@media (min-width: 992px) and (max-width: 1400px) {
    html {
        font-size: 13px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    html {
        font-size: 14px;
    }
}

@media (min-width: 1500px) and (max-width: 1920px) {
    html {
        font-size: 14px;
    }
}

@media (min-width: 1920px) {
    html {
        font-size: 15px;
    }
}

@media (min-width: 2000px) {
    html {
        font-size: 16px;
    }
}