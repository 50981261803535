.main__first__section__about {
    width: 100vw;
}

.main__first__section__container__about {
    position: relative;
    width: 100vw;
}

.main__first__section__background__about {
    width: 100%;
    height: 100vh;
    transform: translate(-8%, 0);
}

.main__first__section__title__about {
    position: absolute;
    top: 0%;
    color: var(--white);
    height: 100vh;
    width: 100%;
    background: rgb(32, 32, 32);
    background: linear-gradient(180deg, rgba(32, 32, 32, 0.44534891593356096) 26%, rgba(21, 26, 47, 0.9252743333661589) 80%);
}

.main__title__about {
    letter-spacing: .2rem !important;
    padding: 8rem 1rem;
}

@media (max-width:992px) {
    .main__first__section__background__about {
        width: 100%;
        object-position: center;
        object-fit: cover;
        transform: none;
    }
}