.proyect-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $dark-grey;
  .text-container {
    margin: 4rem 0 7rem 0;
    h2.nh-medium.text-center {
      font-size: 2.5rem;
      letter-spacing: .25rem;
    }
    &::before {
      top: 0;
      left: 0;
      opacity: .05;
      font-size: 14rem;
      position: absolute;
      content: 'Proyecto';
      letter-spacing: .5rem;
      text-transform: uppercase;
      font-family: 'NeueHaas-Bold';
      transform: translate(0%, -50%);
    }
  }
  .first-row {
    margin-bottom: 3rem;
    .text-content {
      padding: 8rem;
      line-height: 2rem;
      font-size: 1.2rem;
      background-color: $light-grey;
      @media (max-width: 575px) {
        padding: 5rem 6rem;
      }
    }
    .icon-arrow-right {
      right: 0;
      z-index: 1;
      width: 90px;
      height: 90px;
      display: flex;
      padding: 1.5rem;
      font-size: 2.5rem;
      position: absolute;
      color: $light-grey;
      align-items: center;
      justify-content: center;
      background-color: #000;
      @media (max-width: 991px) {
        transform: rotate(270deg);
      }
    }
    .project-image-1 {
      width: 100%;
      height: 70vh;
      object-fit: cover;
      box-shadow: rgba(50, 50, 93, 0.35) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
  }
  .second-row {
    margin-bottom: 3rem;
    .text-content {
      padding: 7rem;
      color: #fff;
      line-height: 2rem;
      font-size: 1.2rem;
      background-color: #000;
      @media (max-width: 575px) {
        padding: 5rem 6rem;
      }
    }
    .eleven-logo {
      z-index: 1;
      width: 90px;
      height: 90px;
      padding: 1.5rem;
      align-self: flex-start;
      background-color: $light-grey;
    }
    .project-image-2 {
      width: 100%;
      height: 60vh;
      object-fit: cover;
      box-shadow: rgba(50, 50, 93, 0.35) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      @media (max-width: 991px) {
        margin-bottom: -8rem;
      }
    }
  }
  .third-row {
    margin-bottom: 8rem;
    .text-content {
      padding: 7rem;
      line-height: 2rem;
      font-size: 1.2rem;
      background-color: $light-grey;
      @media (max-width: 575px) {
        padding: 5rem 6rem;
      }
    }
    .icon-arrow-right {
      right: 0;
      z-index: 1;
      width: 90px;
      height: 90px;
      display: flex;
      padding: 1.5rem;
      font-size: 2.5rem;
      position: absolute;
      color: $dark-grey;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      @media (max-width: 991px) {
        transform: rotate(270deg);
      }
    }
    .project-image-3 {
      width: 100%;
      height: 65vh;
      object-fit: cover;
      box-shadow: rgba(50, 50, 93, 0.35) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
  }
}
