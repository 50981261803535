.Inicio {
    .lotes {
        padding-top: 20vh;
        padding-bottom: 10vh;
        background-color: #000000;
        h2 {
            color: #fff;
            font-weight: 200;
            font-size: 4rem;
            line-height: 1.2;
            span {
                color: var(--primary-color);
            }
        }
        p {
            font-size: 1.5rem;
            color: #fff;
        }
        .btn {
            margin-top: 8vh;
        }
        .grid-system {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(23%, 2fr));
            grid-template-rows: minmax(10vh, auto);
            grid-auto-flow: dense;
            height: 100vh;
            grid-gap: 20px;
        }
        .separator {
            width: 100%;
            height: 1px;
            display: block;
            margin: 20vh 0;
            background-color: #FFFFFF;
        }
        .content-slider {
            padding-bottom: 20vh;
            span {
                color: #fff;
                font-size: .9rem;
                font-family: 'LEMON MILK';
            }
            h2 {
                font-size: 4.5rem;
                color: var(--primary-color);
                font-weight: 300;
            }
            p {
                font-size: 1.5rem;
                font-weight: 300;
            }
            .btn-square {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                color: var(--secondary-color);
                border-radius: 50%;
                .rotate {
                    transform: rotate(-180deg);
                }
            }
        }
        .slider-item {
            height: 50vh !important;
        }
    }
}

@media (max-width:1600px) {
    .Inicio {
        .lotes {
            .content-slider {
                h2 {
                    font-size: 4rem;
                }
            }
        }
    }
}

@media (max-width:992px) {
    .Inicio {
        .lotes {
            padding-top: 10vh;
            padding-bottom: 5vh;
            h2 {
                font-size: 2.5rem;
                line-height: 1;
                color: #fff;
            }
            p {
                color: #fff !important;
                font-size: 1.1rem;
            }
            .separator {
                margin: 10vh 0;
            }
            .grid-system {
                display: block;
                grid-template-columns: unset;
                grid-template-rows: unset;
                grid-auto-flow: unset;
                height: unset;
                grid-gap: unset;
                .grid_item {
                    height: 35vh !important;
                    img {
                        height: 35vh !important;
                    }
                    .hover {
                        background-color: rgba(var(--secondary-color), .4);
                    }
                }
            }
            .content-slider {
                padding-bottom: 5vh;
                span {
                    color: #fff;
                    font-size: .9rem;
                    font-family: 'LEMON MILK';
                }
                h2 {
                    font-size: 2.5rem;
                    color: var(--primary-color);
                    margin: 1rem 0;
                    font-weight: 300;
                }
                p {
                    font-size: 1.2rem;
                    font-weight: 300;
                    width: 100%;
                }
            }
            .slider-item {
                height: 50vh !important;
            }
            .btn-square {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                color: var(--secondary-color);
                border-radius: 50%;
                .rotate {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}