    .main__first__section__desarrollo {
        width: 100vw;
    }
    
    .main__first__section__container__desarrollo {
        position: relative;
        width: 100%;
    }
    
    .main__first__section__background__desarrollo {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: center;
    }
    
    .main__first__section__title__desarrollo {
        position: absolute;
        top: 0%;
        color: var(--white);
        height: 100vh;
        width: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, #202020A7 60%, #151a2fc7 90%);
    }
    
    .main__first__section__title__container__desarrollo {
        margin-top: 25vh !important;
    }
    
    @media (max-width: 768px) {
        .main__first__section__title__container__desarrollo {
            margin-top: 10vh !important;
            p {
                font-size: 1.2rem;
                br {
                    display: none;
                }
            }
        }
    }