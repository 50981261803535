.main__first__section__contact {
  // width: 100vw;
}

.main__first__section__container__contact {
  position: relative;
  width: 100%;
  z-index: 1;
}

.main__first__section__background__contact {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100vh;
}

.main__first__section__title__contact {
  position: absolute;
  top: 0%;
  color: var(--white);
  height: 100vh;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4939017843465511) 26%,
    rgba(0, 0, 0, 0) 48%,
    rgba(21, 26, 47, 0.94) 90%
  );
}

.main__title__contacto__contact {
  position: absolute;
  bottom: 15rem;
  letter-spacing: 0.2rem;
}

.bgCircle_contacto {
  width: 63vw;
  position: absolute;
  bottom: -45vh;
  right: -50vh;
  display: none;
}

@media screen and (min-width: 720px) {
  .main__title__contacto__contact {
    position: absolute;
    // text-align: right;
    bottom: 15rem;
  }
}

@media (max-width: 992px) {
  .main__second__section__contacto {
    padding: 3rem 0 !important;
    p {
      line-height: 1.4;
    }
  }
}
