.novedades {
    .layout {
        .content {
            .div_section_form {
                background-color: #fff;
                .container-fluid {
                    .div_news_container {
                        border-color: $primary-color;
                        .div_vertical {
                            p {
                                color: #1f1f1f;
                                background-color: #fff;
                            }
                        }
                        .row {
                            .div_title {
                                border-color: $primary-color;
                            }
                            .div_first {
                                h3 {}
                                p {
                                    color: #1f1f1f
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}