.novedades {
  .layout {
    .main {
      background-color: $secondary-color;
      padding-top: 10rem;
      padding-bottom: 5rem;
      min-height: fit-content;
      z-index: 0;
      .circlePrimary {
        position: absolute;
        right: 0;
        top: -5rem;
        width: 86rem;
        z-index: -1;
      }
      .container {
        .div_breadcrums {
          margin-bottom: 10rem;
        }
        .hilera {
          .div_selected {
            z-index: 1;
            .div_search {
              border: 2px solid #fff;
              padding: 0.5rem 1.5rem;
              min-height: 5rem;
              //   border-radius: 10px;
              .div_first_search {
                .icon-label-solid {
                  color: $primary-color;
                  max-width: min-content;
                }
                button {
                  background: transparent;
                  border: none;
                }
              }
              .div_second_search {
                span {
                  color: $color-0;
                }
              }
            }
            .btn-search {
              border: 2px solid $color-1;
              color: $color-1;
              background-color: transparent;
              transition: all 0.3s;
              letter-spacing: 3px;
              border-radius: 0 0 30px 0;
              height: auto;
              i {
                font-size: 1.5rem;
              }
              &:hover {
                color: $color-0 !important;
                border-color: $color-0;
                border-radius: 0;
                z-index: 2;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .novedades {
    .layout {
      .main {
        padding-top: 6rem;
        padding-bottom: 3rem;
        .circlePrimary {
          width: 33rem;
        }
        .container {
          max-width: none;
          .div_breadcrums {
            margin-bottom: 3rem;
          }
          .hilera {
            .div_selected {
              .div_search {
                padding: 1rem 1.3rem;
                .div_first_search {
                  .icon-label-solid {
                    font-size: 2rem !important;
                  }
                  button {
                  }
                }
                .div_second_search {
                  // width: 100%;
                  // overflow: scroll;
                  // height: 3rem;
                  // width: 3rem;
                  button {
                    height: 3rem;
                    padding: 0.5rem 1.7rem 0.5rem 1.7rem;
                    i {
                      right: 1rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
