.section__five {
    background-color: $dark-grey;
    padding: 6rem 0;
    position: relative;
}

.title-container {
    &::before {
        top: 60%;
        left: 0;
        opacity: .05;
        content: 'Contactános';
        font-size: 14rem;
        position: absolute;
        letter-spacing: .5rem;
        text-transform: uppercase;
        font-family: 'NeueHaas-Bold';
        transform: translate(0%, -30%);
    }
}

.section__five__container {
    padding: 8rem 0;
}

.section__five__title {
    font-size: 2.1rem !important;
    text-transform: uppercase;
    text-align: center;
}

// .section__five a {
//     &:hover {
//         text-decoration: none;
//         color: var(--btn-primary-border) !important;
//     }
// }

.section__five__arrow__top {
    right: 5%;
    bottom: 8vh;
    width: 5rem;
    height: 5rem;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    background: #fff;
    transition: all .6s;
    @media (max-width: 992px) {
        // right: 3%;
        width: 4rem;
        height: 4rem;
        transform: translate(0, 70%);
    }
    &::before {
        bottom: 0;
        opacity: 0;
        color: #fff;
        content: 'TOP';
        position: absolute;
        transition: all .6s;
    }
    img.icon-arrow-top {
        margin-bottom: 0;
        transition: all .6s;
        filter: brightness(0.5);
        transform: rotate(90deg);
    }
    &:hover {
        background-color: $black;
        img.icon-arrow-top {
            filter: none;
            margin-bottom: 15px;
        }
        &::before {
            opacity: 1;
            bottom: .5rem;
            content: 'TOP';
        }
    }
}

.texto-primary {
    color: #B18442 !important;
}

.contact {
    list-style: none;
    padding: 0;
}

.contact__item {
    font-size: 1.2rem;
    color: #fff;
    position: relative;
    z-index: 99;
    a {
        color: #fff;
        &:hover {
            color: #000;
            text-decoration: none;
        }
    }
    &:hover {
        color: #000;
        text-decoration: none;
    }
}

.contact__email {
    font-size: 1.2rem;
}

.elipse_bg {
    display: none;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
}

@media screen and( min-width: 728px) {
    .contact__email {
        font-size: 1.5rem !important;
    }
    .contact__item {
        font-size: 1.5rem !important;
    }
}

@media (max-width:992px) {
    .section__five {
        padding: 0;
        margin-top: -5rem;
    }
    .section__five__container {
        padding: 1rem 0 3rem 0;
    }
    .contact {
        text-align: center;
    }
    #formContact {
        text-align: center;
        .form-control {
            text-align: left;
        }
        button {
            margin-left: auto!important;
            margin-right: auto!important;
        }
    }
}