.favoritos {
    .main {
        padding-top: 20vh;
        h2 {
            font-size: 5rem;
            margin-bottom: 1rem;
            font-weight: 300;
        }
        &.dark {
            background-color: var(--secondary-color);
            h2 {
                color: #fff;
            }
        }
    }
}

@media (max-width:992px) {
    .favoritos {
        .main {
            padding-top: 15vh;
            h2 {
                font-size: 3rem;
                margin-bottom: 2rem;
            }
        }
    }
}