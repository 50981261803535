.propiedad {
    .main {
        // background-position: center;
        // background-attachment: fixed;
        // background-size: cover;
        // background-repeat: no-repeat;
        // height: calc(100vh - 50px) !important;
        height: 100vh;
        min-height: unset;
        overflow: hidden;
        position: relative;
        // margin-top: -65px;
        .breadcrumb {
            margin-bottom: 3rem !important;
        }
        img.bg-main-prop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            z-index: -1;
        }
        .content-main {
            // height: calc(100vh - 114px) !important;
            height: calc(100vh - 40px) !important;
            color: #fff;
            // padding-bottom: 4rem;
            padding-bottom: 0;
            z-index: 10;
            position: relative;
            .btn-square {
                padding: 0;
                width: 5rem;
                color: #fff;
                border-radius: 50%;
                // border: 1px solid #fff;
                background: $light-grey!important;
                height: 5rem;
                font-size: 1.5rem;
                margin-right: 3rem;
                line-height: 1;
                opacity: 1 !important;
                transition: .3s ease all;
                color: #fff;
                img.icon-camera {
                    width: 35px;
                    transition: .3s ease all;
                }
                .count {
                    position: absolute;
                    left: 45%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: var(--secondary-color);
                    line-height: 1;
                    font-size: 1.2rem;
                    z-index: 0;
                    font-weight: 400;
                    opacity: 0;
                }
                i {
                    color: #fff;
                    margin: 0;
                    transition: .3s ease all;
                }
                &:hover {
                    border-color: transparent;
                    background-color: $black !important;
                    .count {
                        opacity: 1;
                    }
                    i {
                        font-size: .95rem;
                        opacity: 1;
                        transform: translateX(30px);
                        color: var(--secondary-color);
                    }
                    img.icon-camera {
                        width: 41px;
                    }
                }
                &::before {
                    content: "";
                    display: none;
                    padding-top: 4px;
                    font-size: 1.7rem;
                }
            }
            h2 {
                font-size: 3.5rem;
                letter-spacing: .2rem;
            }
            .btn {
                padding: 0;
                width: 5.5rem;
                height: 5.5rem;
                font-size: 1.5rem;
                img {
                    width: 100%;
                    opacity: 0;
                    height: calc(100vh - 114px) !important;
                }
            }
        }
    }
}

@media (max-width:992px) {
    .propiedad {
        .main {
            height: 100vh;
            background-attachment: unset;
            position: relative;
            min-height: 100vh;
            &::before {
                opacity: .6;
            }
            &::after {
                height: 100vh;
                background-color: #1f1f1f;
                opacity: .4;
                background-image: none;
            }
            .content-main {
                padding-bottom: 0;
                height: 100vh;
                h2 {
                    font-size: 2rem;
                    font-weight: 300;
                    line-height: 1.1;
                    padding-left: 0;
                    max-width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    margin-bottom: 0 !important;
                    .location {
                        font-size: 1rem;
                    }
                }
                .btn-square {
                    width: 4rem;
                    position: absolute !important;
                    left: 1rem;
                    bottom: 5vh;
                    height: 4rem;
                    font-size: 1rem;
                }
            }
            .btn {
                width: 4rem;
                height: 4rem;
                position: absolute !important;
                left: calc(0%);
                bottom: 5vh;
                font-size: 1rem;
            }
        }
    }
}