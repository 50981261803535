.navbar {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 99999;
    display: none;
    ul li a {
        letter-spacing: 4px;
        padding: .8rem 1rem;
        &.active{
            color: var(--primary-color) !important;
        }
    }
}

.nav-link {
    letter-spacing: .2rem;
    transition: all .3s;
    text-transform: uppercase;
    color: var(--white) !important;
    background-color: #00000000 !important;
    &:hover {
        color: var(--primary-color) !important;
    }
    &:active {
        color: var(--primary-color) !important;
    }
}