.div_card_new {
    // width: 100%;
    // height: 100%;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    .img_card {
        transition: 1s ease all !important;
        object-fit: cover;
        object-position: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }
    .card_new {
        width: 65%;
        background: $bg-card;
        z-index: 1;
        .content_card {
            .content_body {
                padding: 0px 1rem 0 0;
                .card_date {
                    opacity: 0.87;
                }
                h3 {
                    min-height: 7rem;
                    text-transform: uppercase;
                    line-height: 1.2;
                    font-size: 1.4rem;
                }
                p {
                    font-size: 1rem;
                    min-height: 8rem;
                }
            }
            hr {
                height: 2px;
                background: #f1f1f1;
                opacity: 0.5;
            }
            //   .publicated_by {
            //     p {
            //       color: $primary-color;
            //     }
            //   }
            .div_pill {
                min-height: 7.5rem;
            }
            .see_more {
                text-decoration: none;
                align-items: center;
                span {
                    color: $color-0;
                    text-decoration: none;
                    text-decoration: underline;
                }
                &:hover {
                    span {
                        color: $color-1;
                    }
                }
                // p {
                //   font-size: 0.8rem;
                //   margin: 0;
                //   margin-left: 0.8rem;
                //   color: $primary-color;
                // }
            }
        }
    }
    &:hover {
        .img_card {
            transform: scale(1.2);
            transition: 2s ease all !important;
        }
    }
}

@media (max-width: 1700px) {
    &.home-card {
        .div_pill {
            min-height: 4rem !important;
            span {
                font-size: .9rem !important;
            }
        }
        h3 {
            font-size: 1.1rem !important;
        }
        p {
            font-size: 1.1rem !important;
        }
        .pill {
            font-size: .9rem !important;
        }
        .see_more span {
            font-size: 1.1rem !important;
        }
    }
}

@media (max-width: 1300px) {
    .div_card_new {
        .card_new {
            padding: 1.5rem !important;
            .content_card {
                .content_body {
                    padding: 0 0 0 0;
                    .card_date {}
                    h3 {
                        min-height: 6rem;
                        font-size: 1rem !important;
                    }
                    p {
                        font-size: 1rem !important;
                        min-height: 6rem;
                    }
                }
                .div_pill {
                    min-height: 5.5rem;
                    .pill {
                        padding: 0.2rem 1rem;
                        margin-right: 0.5rem;
                        font-size: .7rem;
                    }
                }
                hr {}
                .publicated_by {
                    p {}
                }
                .see_more {
                    span {
                        font-size: .9rem !important;
                    }
                    .icon-arrow-light {}
                }
            }
        }
        &:hover {}
    }
}

@media (max-width:992px) {
    .div_card_new {
        .card_new {
            padding: 1rem 1rem !important;
            width: 65% !important;
            .content_card {
                .content_body {
                    h3 {
                        font-size: .9rem !important;
                    }
                    p {
                        font-size: .8rem !important;
                    }
                }
                .div_pill {
                    min-height: 4.5rem;
                    .pill {
                        font-size: .6rem;
                    }
                }
            }
        }
    }
}