.Inicio {
    .about {
        background-color: #0f0f0f;
        padding: 12vh 0;
        position: relative;
        .index-1 {
            right: 0;
            .line-vertical {
                opacity: .2;
            }
        }
        .sello {
            position: absolute;
            top: -7.5vh;
            width: 15vh;
            z-index: 99;
            height: 15vh;
            right: 20vw;
        }
        h2 {
            color: #fff;
            font-weight: 400;
            font-family: 'LEMON MILK';
            font-size: 4rem;
            margin-bottom: 7vh;
        }
        span {
            font-size: .9rem;
            font-weight: 300;
            font-family: 'LEMON MILK';
            margin-top: 1rem;
            color: var(--primary-color);
            .line {
                width: 10vw;
                background-color: var(--primary-color);
                height: 1px;
                position: relative;
                display: block;
            }
        }
        p {
            color: #fff;
            font-weight: 200;
            font-size: 1.5rem;
            margin-bottom: 4rem;
        }
    }
}

@media (max-width:992px) {
    .Inicio {
        .about {
            h2 {
                font-size: 2.5rem;
                margin-bottom: 5vh;
            }
            .sello {
                width: 10vh;
                right: 10vw;
            }
            p {
                font-size: 1.1rem;
                line-height: 1.2;
                margin-top: 2rem;
                // br {
                //     display: none !important;
                // }
            }
        }
    }
}