.header__menu__mobile {
    position: relative;
    top: -80%;
    left: -5000px;
    width: 100%;
    height: 100vh;
    background-color: var(--secondary-color);
    opacity: 0;
    transition: all 0.3s;
}

.active {
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s;
    color: white !important;
}

.header__menu__mobile__icon {
    width: 55vw;
    height: 55vw;
    position: absolute;
    left: -20%;
    top: -90px;
    border-radius: 50%;
    background-color: var(--primary-color);
    z-index: 99;
}

.header__menu__mobile__icon>img {
    z-index: 1;
    position: absolute;
    bottom: 8vw;
    left: 22vw;
    width: 25vw;
    height: 25vw;
}

.header__button__container {
    position: absolute;
    top: 50px;
    right: 30px;
    width: 40px;
    height: 40px;
    z-index: 99;
}

.bar {
    width: 35px;
    height: 3px;
    background-color: var(--primary-color);
    margin: 6px 0;
    transition: 0.4s;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-5px, 6px);
    transform: rotate(-45deg) translate(-5px, 6px);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-7px, -8px);
    transform: rotate(45deg) translate(-7px, -8px);
}