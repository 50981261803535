.Inicio {
    .tasaciones {
        background-color: #fff;
        .container-fluid {
            overflow: hidden;
            padding-top: 11vh;
            padding-bottom: 11vh;
            position: relative;
            .index-1 {
                .line-vertical {
                    border-left: 1px solid #0F0F0F;
                    opacity: .2;
                }
            }
            span {
                font-size: .9rem;
                font-family: 'LEMON MILK';
                font-weight: 400;
                color: #0F0F0F;
                display: block;
                margin-bottom: 2.5rem;
            }
            h2 {
                font-size: 5.2rem;
                color: #0F0F0F;
                line-height: 1.2;
                font-weight: 300;
            }
            .img-cut {
                position: relative;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                &::before {
                    content: '';
                    background: linear-gradient(45deg, transparent 80%, #fff 7%);
                    transform: rotate(180deg);
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    bottom: -1px;
                    right: -1px;
                }
            }
            .btn-square {
                margin-top: 7rem;
                z-index: 3;
                color: var(--secondary-color);
                text-decoration: none;
                position: relative;
                &:hover {
                    color: #fff;
                }
            }
        }
        #sub {
            height: 40vh;
            display: block;
            width: 100%;
            background-position: center;
            background-attachment: fixed;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("../../images/home-tasaciones_sub.jpg");
        }
    }
}

@media (max-width:1600px) {
    .Inicio {
        .tasaciones {
            h2 {
                font-size: 4rem;
            }
        }
    }
}

@media (max-width:1200px) {
    .Inicio {
        .tasaciones {
            h2 {
                font-size: 3.5rem;
            }
        }
    }
}

@media (max-width:992px) {
    .Inicio {
        .tasaciones {
            padding-top: 3vh;
            padding-bottom: 0vh;
            .container-fluid {
                padding-top: 5vh;
                span {
                    font-weight: 300;
                    margin-bottom: 0;
                }
                h2 {
                    margin: 2rem 0 2.5rem 0;
                    font-size: 2rem;
                    line-height: 1.1;
                }
                .btn {
                    padding: 1rem 2rem;
                    margin-top: 3rem;
                    display: block;
                }
                #sub {
                    display: none;
                }
                .btn-square {
                    margin-left: auto;
                    margin-top: 5vh;
                    text-align: right;
                }
            }
        }
    }
}