.main__third__section {
    background-color: var(--secondary-color);
    padding: 8rem 0;
    a {
        color: #fff;
        &:hover {
            color: var(--btn-primary-border);
            text-decoration: none;
        }
    }
}

.main__location__map {
    width: 100%;
    height: 381px;
    max-width: 781px;
}

.main__image__location {
    width: 100%;
    height: 100%;
}

.main__information {
    width: 100%;
    max-width: 781px;
    height: auto;
    background-color: var(--primary-color);
    border-bottom-right-radius: 6rem;
}

// .main__location__container {
//   height: 381px;
// }
// .main__location__map {
//   width: 771px;
//   height: 100%;
// }
// .main__image__location {
//   height: 100%;
//   width: 771px;
// }
// .main__information {
//   background-color: var(--primary-color);
//   height: 100%;
//   width: 100%;
//   border-bottom-right-radius: 0;
//   padding: 2rem 0;
// }
// .main__information__item {
//   width: 300px;
//   height: auto;
// }
.item__icon {
    font-size: 2rem;
}

// .main__information__container {
//   width: 771px;
// }
@media screen and (min-width: 1024px) {
    .main__location__map {
        width: 50%;
    }
    .main__information {
        width: 50%;
        height: 381px;
    }
    //   .main__image__location {
    //     height: 35.2vmin;
    //   }
    //   .main__information {
    //     height: 35.2vmin;
    //     width: 100%;
    //     border-bottom-right-radius: 6rem;
    //   }
    //   .main__information__container {
    //     max-width: 800px !important;
    //   }
}

@media (max-width:992px) {
    .main__third__section {
        padding: 2rem 0 3rem 0 !important;
    }
}