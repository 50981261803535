.Emprendimiento {
    .main {
        height: 100vh;
        // margin-top: -114px;
        // margin-bottom: 10vh;
        .art {
            .text-content {
                z-index: 2;
                height: 100vh;
                // padding-top: 10vh;
                h2 {
                    font-size: 3.5rem;
                    letter-spacing: 1px;
                }
                p {
                    font-size: 1.3rem;
                    letter-spacing: 1.5px;
                    line-height: 1.4;
                    max-width: 35vw;
                    margin-bottom: 2.5rem;
                }
                a.cta-button {
                    margin: 0;
                    cursor: pointer;
                    padding: .7rem 1.4rem;
                    height: 45px;
                    width: fit-content;
                    color: #fff;
                    background-color: $light-grey;
                    border: none;
                    outline: unset;
                    text-decoration: none;
                    border-radius: 20px!important;
                    letter-spacing: .12rem;
                    font-size: .9rem;
                    transition: background-color .5s;
                    img.icon-arrow-bottom {
                        width: 13px;
                        margin-left: 0.5rem;
                        margin-top: -0.2rem;
                        transform: rotate(270deg);
                        transition: margin-top .5s;
                    }
                    &:hover {
                        background-color: $black;
                        img.icon-arrow-bottom {
                            margin-top: .15rem;
                        }
                    } 
                }
            }
        }
        .owl-carousel {
            height: calc(100vh);
            // margin-top: -114px;
            // overflow: hidden;
            position: absolute;
            .owl-item {
                margin: 0;
                position: relative;
                img,
                .opacity {
                    object-fit: cover;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: calc(100vh);
                }
                .opacity {
                    z-index: 1;
                    opacity: .7;
                    background-color: #202020A7;
                }
            }
        }
    }
}

@media (max-width:992px) {
    .Emprendimiento {
        .main {
            .art {
                .text-content {
                    text-align: center;
                    // padding-top: 10vh;
                    h2 {
                        font-size: 2.5rem;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                    }
                    p {
                        font-size: 1rem;
                        max-width: 90vw;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    a.cta-button {
                        align-self: center;
                    }
                }
            }
        }
    }
}