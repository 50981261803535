// .image__unities__bg {
//     position: absolute;
//     top: -50%;
//     left: 0;
// }

// .unities {
//     .proyect-bg.tag-filter {
//         padding: .4rem .8rem;
//         font-size: .9rem;
//         border-radius: 15px;
//         cursor: pointer;
//         border: 1px solid #fff;
//         background: transparent !important;
//         transition: .4s ease all;
//         &.active {
//             background: #fff !important;
//             border: 1px solid;
//             color: var(--secondary-color);
//         }
//     }
// }

// .unity {
//     margin-top: 10vh;
// }
.div_unidades {
  background: transparent;
  position: relative;
  margin-top: 15rem;
  margin-bottom: 4rem;
  .collapsed .sub-title {
    opacity: 0;
  }
  .fluid-1 {
    position: relative;
    h2 {
      color: $color-1;
    }
  }
  .title-container {
    &::before {
      top: 0;
      left: 0;
      opacity: .05;
      font-size: 14rem;
      position: absolute;
      content: 'Ubicación';
      letter-spacing: .5rem;
      text-transform: uppercase;
      font-family: 'NeueHaas-Bold';
      transform: translate(0%, -45%);
    }
  }
}

.fluid-2 {
  h3 {
    color: $color-1;
    opacity: 0.5;
    margin-top: 2rem;
  }
  .unities {
    .div_ambientes {
      .div_tags {
        // justify-content: space-evenly;
        .tag-filter {
          // padding: 1rem 2rem;
          border-radius: 20px;
          cursor: pointer;
          border: 1px solid transparent;
          background: $light-grey !important;
          transition: 0.4s ease all;
          color: $color-1;
          &.active {
            background: $mid-black !important;
            border: 1px solid #fff;
            color: #fff !important;
          }
        }
      }
    }
    .div_pisos {
      .unity {
        // border-bottom: 1px solid $color-1;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &.collapsed {
          .col_arrow {
            i {
              // transform: rotate(90deg) !important;
            }
          }
        }
        .head-row {
          border-top: solid 1px #919191;
          .unity-font {
            color: $color-1;
          }
          .col_arrow {
            i {
              color: $color-1;
              transform: rotate(46deg);
            }
          }
        }
        .close-deployable {
          .unity-row {
            .unity-font {
              color: $light-grey!important;
            }
            .col-1 > i.icon-arrow.cursor-pointer {
              color: #fff;
              padding: 0.4rem;
              font-size: 10px;    
              border-radius: 25px;
              margin-right: -0.25rem;
              background-color: #5d635f;
              transition: all .5s;
              &:hover {
                cursor: pointer;
                background-color: $black;
              }
            }
          }
        }
        &[aria-expanded="true"] {
          .head-row {
            .col_arrow {
              vertical-align: text-top;
              i.icon-delete {
                transform: rotate(0deg);
                &:before {
                  padding: 0 0.4rem;
                  letter-spacing: -2px;
                  font-size: 1.25rem;
                  white-space: nowrap;
                  content: "--" !important;
                }
              }
            }
          }
        }
      }
    }
    .div_unidad_inactiva {
      p {
        color: $color-1;
        margin-top: 7rem;
      }
    }
  }
}

@media (max-width: 1600px) {
  .div_unidades {
    .fluid-1 {
    }
  }
}

@media (max-width: 1600px) {
  .div_unidades {
    .fluid-1 {
      p {
      }
      h2 {
      }
    }
    .fluid-2 {
      h3 {
      }
      .unities {
        .div_ambientes {
          .div_tags {
            .tag-filter {
              &.active {
              }
            }
          }
        }
        .div_pisos {
          .unity {
            &.collapsed {
              .col_arrow {
                i {
                }
              }
            }
            .head-row {
              .unity-font {
                font-size: 1rem !important;
              }
              .col_arrow {
                i {
                }
              }
            }
            .close-deployable {
              .unity-row {
                .unity-font {
                  font-size: 1rem !important;
                }
              }
            }
          }
        }
        .div_unidad_inactiva {
          p {
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .div_unidades {
    .fluid-1 {
    }

    .fluid-2 {
      padding-left: 1rem;
      padding-right: 1rem;
      h3 {
      }
      .unities {
        .div_ambientes {
          .div_tags {
            justify-content: center;
            .tag-filter {
              font-size: 0.9rem;
              padding: 0.2rem !important;
              &.active {
              }
            }
          }
        }
        .div_pisos {
          .unity {
            &.collapsed {
              .col_arrow {
                i {
                }
              }
            }
            .head-row {
              .unity-font {
                font-size: 0.8rem !important;
              }
              .col_arrow {
                i {
                }
              }
            }
            .close-deployable {
              .unity-row {
                .unity-font {
                  font-size: 0.8rem !important;
                }
              }
            }
          }
        }
        .div_unidad_inactiva {
          p {
          }
        }
      }
    }
  }
}
