.contacto {
    .main {
        .row {
            min-height: 110vh;
            &.total-vh {
                min-height: 110vh;
                padding-bottom: 10rem;
                position: relative;
                // padding-top: 11rem !important;
            }
            .breadcrumb-item {
                color: rgba(255, 255, 255, 0.639);
                &::before {
                    color: #fff;
                }
                a {
                    color: #fff;
                }
            }
            .left-column {
                padding-top: 25vh;
                span {
                    color: #fff;
                    margin-top: 1rem;
                    margin-bottom: 5rem;
                    font-family: 'LEMON MILK';
                    font-size: .85rem;
                    font-weight: 400;
                }
                h2,
                .hover {
                    color: #0F0F0F;
                    font-size: 3.5rem;
                    line-height: 1.2;
                    font-weight: 300;
                    margin-bottom: 5rem;
                    transition: .3s ease all;
                    text-decoration: none;
                }
                .hover {
                    &:hover {
                        margin-left: 20px;
                    }
                }
            }
            .formulario {
                background: #0F0F0F;
                padding: 0 5vw;
                min-height: 110vh;
                padding-bottom: 10rem !important;
                .form-content {
                    padding-top: 25vh;
                    width: 100%;
                    label {
                        font-size: 1.8rem;
                        color: #fff;
                        width: 100%;
                        margin-bottom: 1rem;
                        font-weight: 300;
                    }
                    input,
                    textarea {
                        border: none;
                        width: 100%;
                        font-size: 2.2vh;
                        background-color: transparent;
                        padding-bottom: 1.1vh;
                        color: #fff;
                        font-weight: 300;
                        display: block;
                        margin-bottom: 80px;
                        border-bottom: 1px solid #fff;
                        &::placeholder {
                            color: #fff;
                        }
                    }
                    textarea {
                        margin-bottom: 0;
                    }
                    p {
                        margin-bottom: 80px;
                        color: #fff
                    }
                }
            }
        }
    }
}

@media (max-width:992px) {
    .contacto {
        .main {
            padding-top: 0;
            position: relative;
            &.step-0 {
                padding-top: 20vh;
                .row {
                    min-height: calc(100vh - 20vh);
                    .left-column {
                        padding-top: 0;
                    }
                }
            }
            &.step-1 {
                padding-top: 0;
                .row {
                    .left-column {
                        padding-top: 0rem;
                    }
                    .formulario {
                        min-height: 100vh;
                        padding: 10vh 5vw;
                        padding-top: 4rem;
                        z-index: 100;
                        .form-content {
                            padding-top: 10vh;
                            input,
                            textarea,
                            label {
                                font-size: 1rem;
                                margin-bottom: 1rem;
                            }
                            p {
                                font-size: .9rem;
                                margin-bottom: 2rem;
                            }
                        }
                    }
                }
            }
            &.step-2 {
                max-height: 90vh;
                min-height: 90vh;
                .full-fluid {
                    .row {
                        padding-top: 0vh !important;
                        height: 90vh;
                        min-height: 90vh;
                        position: relative;
                        padding-bottom: 4rem;
                        h2 {}
                        .text-thanks {
                            font-size: 2rem !important;
                            font-weight: 300;
                        }
                    }
                }
            }
            .full-fluid {
                min-height: 90vh;
            }
            .row {
                min-height: unset;
                .left-column {
                    position: relative;
                    z-index: 10;
                    padding-top: 10vh;
                    span {
                        margin-bottom: 1rem;
                        font-size: .85rem;
                        font-family: 'LEMON MILK';
                        text-transform: uppercase;
                        letter-spacing: 2px;
                    }
                    h2,
                    .hover {
                        margin-bottom: 1.5rem;
                        font-size: 1.3rem;
                    }
                    &.step-2-column {
                        padding-top: 3vh;
                    }
                }
                img {
                    height: 90vh;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                }
            }
            .tick {
                width: 5rem;
                margin-bottom: 2rem;
                height: 5rem;
                i {
                    color: #fff;
                    font-size: 1.5rem;
                }
            }
            .btn {
                margin-top: 5vh;
                padding-top: 1rem;
                padding-bottom: .8rem;
                line-height: 1;
                font-size: .9rem;
            }
        }
    }
}