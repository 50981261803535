.card__project {
    height: 65vh;
    overflow: hidden;
    font-family: 'Montserrat';
    .vendido {
        background-color: var(--primary-color);
        color: #fff;
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 1rem 0;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.3rem;
        width: 100%;
        z-index: 99999;
    }
}

.card__project__container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background-color: var(--primary-color);
}

.card__project__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card__project__bg>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom-right-radius: 8rem;
}

.card__project__data {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 99999;
}

.card__project__container::after {
    content: "";
    position: absolute;
    background-color: var(--primary-color);
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: all .3s;
    z-index: 9999;
    pointer-events: none;
}

.card__project__container::before {
    content: "";
    position: absolute;
    background-color: #333;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    top: 0%;
    left: 0%;
    z-index: 10;
}

.card__project:hover .card__project__bg>img {
    border-bottom-right-radius: 0;
}

.card__project:hover .card__project__container::after {
    top: 0%;
    left: 0%;
    transition: all .3s;
    opacity: .64;
    z-index: 99;
}

.card__title {
    position: absolute;
    bottom: 50px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 1.7rem;
    z-index: 99;
    transition: all .3s;
    //
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.card__project:hover .card__title {
    opacity: 0;
    transition: all .3s;
    color: #fff;
}

.card__project:hover .card__data__image>img {
    transform: scale(1);
    opacity: 1;
    transition: all .3s;
}

.card__project:hover .card__data__text {
    opacity: 1;
    transition: all .3s;
    color: #fff;
}

.card__data__image>img {
    transform: scale(2);
    opacity: 0;
    object-fit: cover;
    transition: all .3s;
    width: 9rem !important;
}

.card__data__text {
    text-align: center;
    opacity: 0;
    z-index: 99;
    transition: all .3s;
    //
    p {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        z-index: 99;
    }
}

.development-zoom {
    translate: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 99;
    .zoom-image {
        background-image: url("../../images/signo_mas.svg");
        background-repeat: no-repeat;
        background-position: center;
        height: 10rem;
        width: 10rem;
        display: block;
    }
}