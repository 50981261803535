.main__second__section {
  padding: 8rem 1rem;
  background-color: var(--secondary-color);
  position: relative;
  height: 100vh;
}

.main__second__section__image {
  // width: 60vmin;
  height: 55vh;
  display: none;
}

.main__description__container {
  max-width: 40vw;
}

.main__description__title {
  font-size: 1.5rem;
}

.main__description__bg__circle {
  position: absolute;
  top: 150px;
  left: -400px;
  width: 45rem;
  opacity: 0.5;
}

.card-about {
  position: relative;
  transition: 0.3s ease-in-out all;
  margin-top: 5vh;
  margin-bottom: 1rem;
  &.top-offset {
    margin-top: 25vh;
  }
  .square {
    transition: 0.3s ease-in-out all;
    height: 40vh;
    position: absolute;
    top: 2rem;
    left: -2rem;
    right: 2rem;
    border: 2px solid #fff;
  }
  .image {
    transition: 0.3s ease-in-out all;
    margin-top: -1rem;
    img {
      // max-width: 35vw;
      height: 40vh;
      object-fit: cover;
      object-position: center;
    }
  }
  h3 {
    margin-top: 3rem;
    margin-bottom: 0;
    font-size: 3rem;
    font-weight: 600;
    transition: 0.3s ease-in-out all;
  }
  h5 {
    font-size: 1.1rem;
    color: var(--primary-color);
  }
  p {
    font-size: 1.2rem;
    width: 90%;
    margin-top: 2rem;
    opacity: 0;
    height: 1px;
    transition: 0.3s ease-in-out all;
    // display: none;
  }
  &:hover {
    .image {
      margin-top: 2rem;
      padding-top: 2rem;
    }
    .square {
      top: 0rem;
      right: -2rem !important;
      left: -2rem;
      width: unset;
      height: unset;
      bottom: -2rem;
    }
    p {
      opacity: 1;
      height: 100%;
      // display: block;
    }
    h3 {
      margin-top: 3rem;
    }
  }
}

.title-map {
  font-size: 3rem;
  span {
    color: var(--primary-color);
  }
}

.location-map {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 300;
  margin-bottom: 5rem;
}

.map-about {
  height: 80vh;
}

@media (max-width: 992px) {
  .main__description__container {
    max-width: 90vw;
    margin: auto;
  }
  .main__second__section {
    padding: 3rem 0 1rem 0;
  }
  .text-year {
    font-size: 20vw !important;
  }
  .article__description__title {
    font-size: 15vw;
  }
  .card-about {
    padding: 1rem;
    &.top-offset {
      margin-top: 10vh;
    }
    .square {
      left: -1rem;
      right: -1rem;
      top: -1rem;
      bottom: -1rem;
      height: unset;
      width: unset;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    h3 {
      font-size: 2rem;
      margin-top: 1rem;
      text-align: center;
    }
    h5 {
      font-size: 1rem;
      margin-top: 1rem;
      text-align: center;
    }
    p {
      height: 100%;
      opacity: 1;
      text-align: center;
      font-size: 0.9rem;
      width: 100%;
    }
    &:hover {
      .image {
        padding-top: 0;
        margin-top: -1rem;
      }
      h3,
      h5 {
        margin-top: 1rem;
      }
      .square {
        left: -1rem;
        right: -1rem !important;
        top: -1rem;
        bottom: -1rem;
        height: unset;
        width: unset;
      }
    }
  }
  .title-map {
    font-size: 2rem;
  }
  .location-map {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  .map-about {
    height: unset;
  }
}
