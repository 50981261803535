.emprendimientos {
    background-color: #fff;
    .lotes {
        padding-top: 10vh;
        padding-bottom: 5vh;
        .container-fluid {
            max-width: 80%;
            span {
                font-family: 'LEMON MILK';
                font-size: .85rem;
                font-weight: 400;
            }
            h2 {
                margin: 2rem 0 7rem 0;
                font-size: 5rem;
                font-weight: 200;
            }
        }
    }
}

@media (max-width:992px) {
    .emprendimientos {
        .lotes {
            padding-top: 0rem;
            padding-bottom: 3rem;
            .container-fluid {
                max-width: 100%;
                h2 {
                    margin: 1rem 0 4rem 0;
                    font-size: 2rem;
                }
            }
        }
    }
}