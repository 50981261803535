.navigator-container {
    display: flex;
    position: absolute;
    left: 50vw;
    bottom: 22vh;
    z-index: 99;
    .item-navigator {
        color: #fff;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 3rem;
        .line-navigator {
            width: 3rem;
            display: inline-block;
            background-color: #fff;
            margin: 0 1.5rem;
            height: 1px;
        }
        &.active {
            color: var(--primary-color);
            .line-navigator {
            }
        }
    }
}